import React, { useState, useEffect } from "react";
import api from "../../../requests/api";
import { Table, Button, Modal, Form, Input, Checkbox, Spin, Space, notification } from "antd";
import { useTranslation } from "react-i18next";
const defaultField = {
    key: null,
    label: null,
    order: null,
    visibility: null,
    isNative: null,
};
function CustomerFieldsManager(props) {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [requiredMark, setRequiredMarkType] = useState("optional");
    const [customerFields, setCustomerFields] = useState([]);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [isEditModal, setIsModalEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const tableColumns = [
        {
            title: t("settings.customerFields.uniqueIdentifier"),
            dataIndex: "key",
            key: "key",
        },
        {
            title: t("settings.customerFields.showName"),
            dataIndex: "label",
            key: "label",
        },
        {
            title: t("settings.customerFields.order"),
            dataIndex: "order",
            key: "order",
        },
        {
            title: t("settings.customerFields.visibility"),
            dataIndex: "visibility",
            key: "visibility",
            render: (record) => (record ? t("yes") : t("no")),
        },
        {
            title: t("actions"),
            key: "action",
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => handleEdit(record)}>
                        {t("edit")}
                    </Button>
                    {!record.isNative && (
                        <Button type="link" onClick={() => handleDelete(record)}>
                            {t("delete")}
                        </Button>
                    )}
                </Space>
            ),
        },
    ];
    const onRequiredTypeChange = ({ requiredMarkValue }) => {
        setRequiredMarkType(requiredMarkValue);
    };
    const handleNewButton = function () {
        setIsModalEdit(false);
        form.setFieldsValue(defaultField);
        setIsModalVisible(true);
    };
    const handleCancelModal = () => {
        setIsModalVisible(false);
    };
    const handleOkModal = () => {
        form.validateFields()
            .then((val) => {
                sendForm(val);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleEdit = (record) => {
        setIsModalEdit(true);
        form.setFieldsValue(record);
        setIsModalVisible(true);
    };
    const handleDelete = (record) => {
        Modal.warning({
            title: t("delete"),
            content: (
                <div>
                    <p>{t("settings.customerFields.noInformationLost")}</p>
                </div>
            ),
            onOk: () => deleteCustomerField(record.id),
        });
    };
    const deleteCustomerField = (id) => {
        api.customers()
            .deleteCustomerField(id)
            .then((res) => {
                loadCustomerFields();
            })
            .catch((err) => {
                notification.error({
                    message: t("error"),
                    description: t("serverError"),
                });
            });
    };
    const sendForm = (values) => {
        setIsModalLoading(true);
        if (isEditModal) {
            api.customers()
                .updateCustomerField(values.id, values)
                .then((res) => {
                    loadCustomerFields();
                    setIsModalVisible(false);
                })
                .catch((err) => {
                    notification.error({
                        message: t("error"),
                        description: t("serverError"),
                    });
                })
                .then(() => {
                    setIsModalLoading(false);
                });
        } else {
            const newField = {
                key: values.key,
                label: values.label,
                order: values.order,
                visibility: values.visibility || false,
            };
            api.customers()
                .createCustomerField(newField)
                .then((res) => {
                    loadCustomerFields();
                    setIsModalVisible(false);
                })
                .catch((err) => {
                    notification.error({
                        message: t("error"),
                        description: t("serverError"),
                    });
                })
                .then(() => {
                    setIsModalLoading(false);
                });
        }
    };

    const loadCustomerFields = function () {
        setIsLoading(true);
        api.customers()
            .listCustomerField()
            .then((res) => {
                setCustomerFields(res.data.customerFields.sort((a, b) => a.order - b.order));
            })
            .catch((err) => {
                console.log(err);
            })
            .then(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadCustomerFields();
    }, []);

    return (
        <>
            <Spin spinning={isLoading}>
                <div style={{ display: "flex" }}>
                    <span className="opt-label-title">
                        {t("settings.customerFields.customerFields")}
                    </span>
                    <Button
                        onClick={handleNewButton}
                        style={{ marginLeft: "auto", marginBottom: "15px" }}
                    >
                        {" "}
                        {t("new")}{" "}
                    </Button>
                </div>
                <Table dataSource={customerFields} columns={tableColumns} pagination={false} />
                <Form.Provider onFormFinish={sendForm}>
                    <Modal
                        visible={isModalVisible}
                        onCancel={handleCancelModal}
                        onOk={handleOkModal}
                        title={t("settings.customerFields.newCustomerField")}
                    >
                        <Spin spinning={isModalLoading}>
                            <Form
                                form={form}
                                layout="vertical"
                                onValuesChange={onRequiredTypeChange}
                                requiredMark={requiredMark}
                            >
                                <Form.Item name="id" style={{ display: "none" }}>
                                    <Input hidden />
                                </Form.Item>
                                <Form.Item
                                    label={t("settings.customerFields.uniqueIdentifier")}
                                    name="key"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required"),
                                        },
                                        {
                                            pattern: /^[a-zA-Z_]+$/,
                                            message: t("settings.customerFields.uniqueIdentifierRules"),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t(
                                            "settings.customerFields.uniqueIdentifierAndExample"
                                        )}
                                        disabled={isEditModal}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={t("settings.customerFields.showName")}
                                    name="label"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required"),
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder={t(
                                            "settings.customerFields.showNameAndExample"
                                        )}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={t("settings.customerFields.order")}
                                    name="order"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required"),
                                        },
                                    ]}
                                >
                                    <Input
                                        type="number"
                                        placeholder={t("settings.customerFields.showOrder")}
                                    />
                                </Form.Item>
                                <Form.Item name="visibility" valuePropName="checked">
                                    <Checkbox>
                                        {t("settings.customerFields.visibilityOnUI")}
                                    </Checkbox>
                                </Form.Item>
                            </Form>
                        </Spin>
                    </Modal>
                </Form.Provider>
            </Spin>
        </>
    );
}
export default CustomerFieldsManager;
